import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldBarbara: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Barbara?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_barbara.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Barbara?</h1>
          <h2>
            Not sure if pulling Barbara is the right move? No worries! Check our
            review and guide for her!
          </h2>
          <p>
            Last updated: <strong>01/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="barbara"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>You should pull Barbara if:</p>
        <ul>
          <li>
            You want a versatile support who generates cards for allies and
            manipulates their ranks.
          </li>
          <li>
            You value someone who has strong Defensive Utility with shields and
            healing.
          </li>
          <li>You have a Mental DMG dealing main attacker.</li>
          <li>
            You have a character that would like to cast a lot of incantations.
          </li>
          <li>
            Baaaaaah. OMG SHES SO CUTE IMMA PULL FOR HER (This is the writer’s
            opinion).
          </li>
        </ul>
        <p>
          Barbara is a solid addition to any Timekeeper’s roster, especially as
          a 5-star. Her main appeal lies in generating cards for allies and
          being able to manipulate their ranks. Additionally, she provides
          general support by boosting Mental DMG and reducing the damage the
          team takes.
        </p>
        <p>
          She is a strong character with a fairly interesting niche, and as a
          5-star, she’s not particularly difficult to obtain. However, I
          wouldn’t consider her a must-pull, as her slot could be better
          utilized by a stronger survival-focused character or a more
          specialized utility character, depending on your team’s needs.
        </p>
        <SectionHeader title="Review" />
        <p>
          Another new addition to the 5-Star survival character list, which
          already features consistent characters like Balloon Party to keep you
          alive and Yenisei, who boasts great defensive utility in general.
          However, Barbara is looking to carve out a different type of niche.
        </p>

        <p>
          While Barbara still excels in defense by providing shields to allies
          through her Ultimate and Skill plus Damage Reduction and healing from
          her passive, all of which are very effective at keeping your team
          alive. She brings something unique to the table. One of the statuses
          she can activate is [Visiting Dreams], which limits her moveset to
          just her Ultimate and special incantations. While in this state, her
          carried cards are automatically converted to one of your other allies
          at random, and she gains Moxie every turn for herself.
        </p>
        <p>
          Not only that, but she also generates [Jumping Sheep], which can
          modify the ranking of incantations. The way it works is that the rank
          of the incantation in front of the card increases by 1, while the rank
          of the one behind decreases by 1. This means it’s not a completely
          free rank-up, but if you plan carefully which card you want to boost
          or reduce, it can be highly beneficial.
        </p>
        <p>
          This makes her pair well with AP-hungry characters, as she won’t be
          using too many of her own cards, since they will either be converted
          to allies or enhanced with [Jumping Sheep] to rank them up. Combine
          this with her ability to increase the damage enemies take from Mental
          advantage, and she works perfectly with any Mental DMG characters in
          general. For example, someone like Melania who eats a lot of AP and
          needs a lot of her own cards to be played.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Huge utility in generating incantations for teammates and
                    increasing their Ranks when [Jumping Sheep].
                  </li>
                  <li>
                    Strong defensive support for the whole team providing
                    shields and DMG Taken reduction.
                  </li>
                  <li>
                    Offers Mental DMG Support and once in [Visiting Dreams]
                    Status, she’s a very passive character who doesn’t need to
                    go super often. Pairs exceptionally well with AP-hungry
                    Mental DMG characters like Melania, as Barbara doesn’t
                    compete for cards or AP.
                  </li>
                  <li>
                    Grants healing to herself and others when in the [Visiting
                    Dreams] state, allowing her to stay alive longer in extended
                    battles.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Requires planning to fully utilize [Jumping Sheep]’s
                    rank-shifting mechanic.
                  </li>
                  <li>
                    Relies on [Visiting Dreams] state for many of her strengths
                    so make sure you get her Ultimate.
                  </li>
                  <li>
                    While her self healing will always work, she will only heal
                    other adjacent allies, if her health is about 90%. She also
                    cannot cast shields and cannot serve as the team’s sole
                    sustain
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>Completely fine at P0.</p>
        <p>
          Nothing significantly important as they are all stat increases. Though
          increasing the duration at Portray 5 for [Visiting Dreams] is useful.
          But pulling for Portray 5 is not an easy task even for a 5 Star.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          Barbara’s general defensive utility makes her a viable option in most
          cases where you need a healer or survival character. Since she’s a
          Spirit Afflatus character, she’s easy to fit into most teams if you
          simply need a defensive character or someone to provide healing.
          However, in terms of sheer defensive utility, she falls behind
          Yenisei, and in raw healing output, she’s outclassed by Balloon Party.
          Which is why you should try to pair her with someone who can use the
          Utility she can provide.
        </p>
        <p>
          She works best in a team that features a Mental DMG character who can
          be a bit more AP hungry. Melania, mentioned earlier in the review, is
          a perfect choice, as she benefits greatly from having her Ultimate
          available as often as possible. Marcus, whose entire focus is dealing
          huge damage with high ranking incantations, would benefit greatly also
          in Barbara’s ability to rank up and produce more cards for them.
        </p>
        <p>
          Top-tier damage dealers like Windsong also pair well with her. You can
          still use her with characters like Lucy also. Even though Lucy doesn’t
          benefit from the Mental DMG boost, she doesn’t really need it. She can
          benefit powerful DPS characters just by giving them more incantations.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="marcus"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="melania"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="lucy"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="windsong"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="anjo-nala"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Barbara:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="beyond-wonderland"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="the-fighters-best-wishes"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="that-inquisitive-dear"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="long-night-talk"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          Beyond Wonderland works great for her, as she scales off health and
          can apply debuffs that increase her healing rate, all of which
          synergizes perfectly with her kit. She should be able to utilize every
          aspect of it.
        </p>
        <p>
          The Fighter’s Best Wishes ensures that if her health drops below
          60-80% (depending on the amplification level), she’ll take reduced
          damage.
        </p>
        <p>
          The Inquisitive Dear boosts her healing capabilities and provides
          another way to heal without needing to activate the [Visiting Dreams]
          status which requires her ultimate.
        </p>
        <p>
          Long Night Talk allows her to adopt a more
          damage-focused/shield-focused approach by increasing her damage dealt
          and ATK. Her shield scales off of ATK. However, it takes the longest
          time to set up compared to any of the psychubes mentioned here.
        </p>
        <SectionHeader title="Resonance" />
        <h6>Level 7</h6>
        <StaticImage
          src="../../../images/reverse/generic/barbara_r_1.webp"
          alt="Guides"
        />
        <h6>Level 9</h6>
        <StaticImage
          src="../../../images/reverse/generic/barbara_r_2.webp"
          alt="Guides"
        />
        <h6>Level 10</h6>
        <StaticImage
          src="../../../images/reverse/generic/barbara_r_3.webp"
          alt="Guides"
        />
        <br />
        <StaticImage
          src="../../../images/reverse/generic/barbara_r_4.webp"
          alt="Guides"
        />
        <h5>Resonance Pattern Recommendations:</h5>
        <p>Recommended for using all aspects of Barbara.</p>
        <StaticImage
          src="../../../images/reverse/generic/barbara_r_5.webp"
          alt="Guides"
        />
        <p>This is the best build for an Ultimate/Heal build</p>

        <StaticImage
          src="../../../images/reverse/generic/barbara_r_6.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <p>
          A few suggestions are listed below. 3-People teams are highlighted,
          with the 4th slot added at the side to provide a 4-P variation of the
          same team or even insights on possible variations of the 3-P team.
        </p>
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            Damn Thief got a Sheep
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="melania"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="argus"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support - Debuffer</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="barbara"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Lopera, Pickles</strong>
              </li>
              <li>
                A team centered around Melania’s continuously getting her
                Ultimate to do DMG witth the other supporting her and not using
                too much AP.
              </li>
              <ul>
                <li>
                  Melania’s kit requires her to gather Moxie as quickly as
                  possible to continuously use her Ultimate. This means she can
                  be quite AP-greedy and needs teammates who don’t use too much
                  AP.
                </li>
                <li>
                  Barbara is great because not only does she not use much AP,
                  but she can also produce cards for Melania, allowing her to
                  gain cards even faster. Her Mental DMG increase also directly
                  supports Melania (and in this case, her other teammate Argus),
                  and her general survival/healing can benefit anyone, including
                  Melania.
                </li>
                <li>
                  Argus is here to simply increase the hell out of Melania’s
                  Ultimate. She’ll apply debuffs that will allow Melania to
                  function great as the team main damage dealer. Argus herself
                  also functions great as the 2nd damage dealer.
                </li>
                <li>
                  The damage is already quite strong, but if you need any one
                  for a 4th slot you can go with some more buffers. Lopera in
                  this case fits perfectly with Melania as she buffs Ultimate
                  Buff. I didn’t pick her only because I wanted to take
                  advantage of Barbara’s Mental DMG boost since this is a
                  Barbara team page.
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Don’t need to be mental<span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lucy"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="mercuria"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="barbara"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                Barbara’s Mental DMG increase is useful but far from the most
                important part of her kit. Here’s a team that still utilizes it
                but not for the main damage dealer.
              </li>
              <ul>
                <li>
                  Lucy is one of the strongest characters in the game, a fairly
                  simple damage dealer who can be played in most ways. Press
                  buttons, deal damage, and gain Dynamo. Barbara functions well
                  in her role as a shielder and produces more cards for Lucy to
                  do even more damage. Lucy is a Reality DMG character, but she
                  doesn’t really need the Mental Buff.
                </li>
                <li>
                  Mercuria is a perfect pairing for Lucy, and she also benefits
                  from Barbara’s Mental Buff. She can dispel both negative and
                  positive effects from enemies, gain rank-ups for allies,
                  provide a mass crit rate boost, and increase allies' damage
                  through sheer percentage thanks to Cosmic Energy.
                </li>
                <li>
                  This team is fairly straightforward. For a fourth member, you
                  can choose from numerous other characters, and they should
                  contribute well. Ezra can help generate Moxie and provide
                  additional survival. Lopera’s buffs can help Lucy, and she can
                  convert excess Crit Rate into Crit DMG, which can be useful.
                  Any one should work and you should just pick as needed.
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldBarbara;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Barbara? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Barbara is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
